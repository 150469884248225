<template>
  <b-card title="Projects">
    <b-form>
      <validation-observer ref="simpleRules">
        <form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Project name">
                <validation-provider #default="{ errors }" name="Project name" rules="required">
                  <b-form-input placeholder="Project name" v-model="projectName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Description">
                <validation-provider #default="{ errors }" name="Description" rules="required">
                  <b-form-input placeholder="Description" v-model="projectDescription"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="$route.name === 'projects/edit'">
              <b-form-group label="Status">
                <validation-provider #default="{ errors }" name="Status" rules="required">
                  <v-select :options="statusOpt" variant="primary" v-model="selectedStatus" :clearable="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group label="Owner">
                <validation-provider #default="{ errors }" name="Owner" rules="required">
                  <v-select :options="users" label="name" :state="errors.length > 0 ? false : null" class="w-100"
                    v-model="projectOwner" placeholder="Select the owner" :clearable="false"><template #list-header>
                      <b-button variant="outline-primary" block @click="$refs['createUser'].show()">Create User</b-button>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group label="Members">
                <validation-provider #default="{ errors }" name="Members" rules="required">
                  <v-select :options="users" label="name" multiple placeholder="Select the members" class="w-100"
                    :state="errors.length > 0 ? false : null" v-model="projectMembers">
                    <template #list-header>
                      <b-button variant="outline-primary" block @click="$refs['createUser'].show()">Create User</b-button>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group label="Departaments">
                <validation-provider #default="{ errors }" name="Status" rules="required">
                  <v-select v-model="selectedDepartaments" :options="departaments" label="title" multiple
                    placeholder="Select the departaments" class="w-100" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Start Date">
                <validation-provider #default="{ errors }" name="Start Date">
                  <b-form-datepicker id="start" v-model="projectStartDate">
                  </b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="End Date">
                <validation-provider #default="{ errors }" name="End Date">
                  <b-form-datepicker id="end" v-model="projectEndDate">
                  </b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                @click="validateProject">
                Guardar
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="cancel">
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-form>

    <b-modal ref="createUser" hide-footer hide-header size="lg" no-close-on-backdrop>
      <UserCreate @close="closeModal" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
import UserCreate from '@/components/atoms/users/UserCreate.vue'
import ProjectController from '@/controllers/ProjectsController'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { projectStatusOptions } from '@/utils/status'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

const { log } = require('@/utils/log')

export default {
  components: {
    UserCreate,
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    required
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      selectedDepartaments: null,
      projectName: '',
      statusOpt: projectStatusOptions,
      selectedStatus: { label: 'ACTIVATED', key: 'activated' },
      projectDescription: '',
      projectStatus: '',
      projectOwner: '',
      projectMembers: [],
      projectStartDate: '',
      projectEndDate: '',
      id: '',
    };
  },

  async created() {
    await Promise.all([
      this.$store.dispatch('getAllUsers'),
      this.$store.dispatch("getAllDepartaments")
    ])

    this.id = this.$route.params.id;
    if (this.$route.name === "projects/edit") {
      await this.$store.dispatch("getProject", this.id);
      this.projectName = this.project.title;
      this.projectDescription = this.project.description;
      this.selectedStatus = this.statusOpt.find(s => s.key === this.project.status);
      this.projectOwner = this.project.owner;
      this.projectMembers = this.project.members;
      this.selectedDepartaments = this.project.departaments;
      this.projectStartDate = this.project.start_date;
      this.projectEndDate = this.project.end_date;
    }
  },

  computed: {
    users() {
      return this.$store.state.usersConfig.users ?
        this.$store.state.usersConfig.users
          .filter((user) => user.status === 1) :
        []
    },
    project() {
      return this.$store.state.projectsConfig.project;
    },
    departaments() {
      return this.$store.state.departamentConfig.departaments;
    },
  },

  methods: {
    cancel() {
      const nextRoute = decodeURIComponent(this.$route.query.next || '/projects');
      this.$router.push(nextRoute);
    },
    async validateProject() {
      const isValid = await this.$refs.simpleRules.validate();
      if (isValid) {
        try {
          const members = this.projectMembers.map((member) => member.id);
          const departaments_id = this.selectedDepartaments.map(
            (departament) => departament.id
          );

          const projetoData = {
            title: this.projectName,
            description: this.projectDescription,
            status: this.selectedStatus.key,
            fk_owner: this.projectOwner ? this.projectOwner.id : null,
            members: members,
            departaments_id: departaments_id,
            start_date: this.projectStartDate,
            end_date: this.projectEndDate,
          };
          const projectController = new ProjectController();

          if (this.$route.name != "projects/edit") {
            await projectController.createProject(projetoData);
          } else {
            await projectController.updateProject(this.id, projetoData);
          }
          const nextRoute = decodeURIComponent(this.$route.query.next || '/projects');
          this.$router.push(nextRoute);
        } catch (err) {
          log(err);
        }
      }
    },
    closeModal() {
      this.$store.dispatch('getAllUsers')
      this.$refs['createUser'].hide()
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.v-input--has-state .v-input__control {
  border-color: red;
}
</style>
